import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  Slider,
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  InputLabel,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { Add, Delete, Edit as EditIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteStrategy,
  getAllCoins,
  getStrategyById,
  postStrategy,
  updateStrategy,
} from "../../../../api/strategy";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getExchanges } from "../../../../api/exchange";

// Crear tema personalizado con el color #cc02d6
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc02d6",
    },
    secondary: {
      main: "#F64E60",
    },
  },
});

// Componente TabPanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Componente para parámetros configurables
const ConfigParameters = ({ config, handleConfigChange }) => {
  const parameters = [
    { name: "windowTime", label: "Ventana de tiempo (min)", type: "number" },
    { name: "minVolume", label: "Volumen mínimo (M USDT)", type: "number" },
    { name: "rsi", label: "RSI", type: "number" },
    {
      name: "maxpercVar1h",
      label: "% Variación última hora",
      type: "range",
      min: 1,
      max: 20,
    },
    {
      name: "maxpercVar30m",
      label: "% Variación últimos 30 min",
      type: "range",
      min: 1,
      max: 20,
    },
    {
      name: "maxpercVar10m",
      label: "% Variación últimos 10 min",
      type: "range",
      min: 1,
      max: 20,
    },
    {
      name: "bollingerBands",
      label: "Bandas de Bollinger",
      type: "select",
      options: ["BUY", "NEUTRAL", "BOUGHT"],
    },
  ];

  return (
    <Grid container spacing={3}>
      {parameters.map((param) => (
        <Grid item xs={12} sm={6} key={param.name}>
          <FormControlLabel
            control={
              <Checkbox
                checked={config[param.name]?.active || false}
                onChange={(e) =>
                  handleConfigChange(param.name, "active", e.target.checked)
                }
                name={`${param.name}-active`}
                color="primary"
              />
            }
            label={`Activar ${param.label}`}
          />
          {config[param.name]?.active && (
            <>
              {param.type === "number" && (
                <TextField
                  label={param.label}
                  type="number"
                  value={config[param.name]?.value || ""}
                  onChange={(e) =>
                    handleConfigChange(param.name, "value", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              )}
              {param.type === "range" && (
                <>
                  <Typography id={`${param.name}-slider`} gutterBottom>
                    {param.label}
                  </Typography>
                  <Slider
                    value={
                      Array.isArray(config[param.name]?.value)
                        ? config[param.name].value
                        : [param.min, param.max]
                    }
                    onChange={(e, newValue) =>
                      handleConfigChange(param.name, "value", newValue)
                    }
                    valueLabelDisplay="auto"
                    min={param.min}
                    max={param.max}
                    marks
                    step={1}
                  />
                </>
              )}
              {param.type === "select" && (
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id={`${param.name}-label`}>
                    {param.label}
                  </InputLabel>
                  <Select
                    labelId={`${param.name}-label`}
                    value={config[param.name]?.value || ""}
                    onChange={(e) =>
                      handleConfigChange(param.name, "value", e.target.value)
                    }
                    label={param.label}
                  >
                    {param.options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default function EditStrategiesPage() {
  const isMounted = useRef(true);
  const [strategy, setStrategy] = useState({
    fullName: "",
    description: "",
    bot: "",
    groups: [],
    longActive: true,
    shortActive: true,
    stopLoss1: 0,
    stopLoss2: 0,
    takeProfit1: 0,
    takeProfit2: 0,
    candleInterval: 120,
    emaPeriod: 21,
    atrPeriod: 14,
    filterTop: 2.5,
    filterBottom: -2.5,
    accountAmount: 0,
    percentagePerTrade: 0,
    leverage: 0,
    active: true,
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [allCoins, setAllCoins] = useState([]);
  const [tab, setTab] = useState(0);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null); // Grupo actual para editar
  const [isEditMode, setIsEditMode] = useState(false); // Modo de edición
  const [exchanges, setExchanges] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const strategyId = useParams().id;
  const history = useHistory();
  const bots = ["Scalping", "Swing", "APEX-SCALPING"];

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  // Fetch coins based on exchanges
  const fetchCoins = async (selectedExchanges) => {
    try {
      let coins = [];
      for (let i = 0; i < selectedExchanges?.length; ++i) {
        const act = selectedExchanges[i];
        const exchange = act?.fullName;
        const res = await getAllCoins(exchange);
        if (res.status === 200) {
          coins.push({exchange, symbols: res.data});
        }
      }
      setAllCoins([...coins]);
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not fetch coins for the selected exchange.",
      });
    }
  };

  // Obtener estrategia si existe
  useEffect(() => {
    if (strategyId) {
      getStrategyById(strategyId)
        .then((res) => {
          if (res.status === 200) {
            const exchanges = res?.data?.exchanges;
            let newExchanges = [];
            for (let i = 0; i < exchanges?.length; ++i) {
              const act = exchanges[i].exchangeId;
              newExchanges.push({
                ...act,
                exchangeId: act?._id
              })
            }
            const fetchedStrategy = {...res.data, exchanges: newExchanges};
            setStrategy(fetchedStrategy);
            fetchCoins(fetchedStrategy.exchanges);
            disableLoadingData();
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get strategy.",
          });
          history.push("/strategies");
        });
    } else {
      disableLoadingData();
    }
  }, [strategyId, disableLoadingData, history]);

  // Obtener todas las monedas cuando cambia el exchanges
  useEffect(() => {
    if (exchanges.length > 0) {
      fetchCoins(exchanges);
    }
    return () => {
      isMounted.current = false;
    };
  }, [exchanges]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh])

  useEffect(() => {
    getExchanges()
      .then((res) => {
        if (res.status === 200) setExchanges(res.data);
      })
      .catch((error) => {
        alertError({
          error,
          customMessage: "Could not get exchanges"
        })
      })
  }, [])

  // Agregar un nuevo grupo
  const addGroup = () => {
    setCurrentGroup({
      groupName: "",
      coins: [],
      longConfig: initializeConfig(),
      shortConfig: initializeConfig(),
    });
    setIsEditMode(false);
    setOpenGroupModal(true);
  };

  // Función para inicializar configuración
  const initializeConfig = () => {
    return {
      windowTime: { active: false, value: 0 },
      minVolume: { active: false, value: 0 },
      rsi: { active: false, value: 0 },
      maxpercVar1h: { active: false, value: [1, 20] },
      maxpercVar30m: { active: false, value: [1, 20] },
      maxpercVar10m: { active: false, value: [1, 20] },
      bollingerBands: { active: false, value: "" },
    };
  };

  // Editar un grupo existente
  const editGroup = (group, index) => {
    setCurrentGroup({ ...group, index });
    setIsEditMode(true);
    setOpenGroupModal(true);
  };

  // Eliminar un grupo
  const removeGroup = (index) => {
    setStrategy((prev) => ({
      ...prev,
      groups: prev.groups.filter((_, i) => i !== index),
    }));
  };

  // Manejar cambios en el grupo dentro del modal
  const handleGroupChange = (field, value) => {
    if (field === "exchange") {
      setCurrentGroup((prev) => ({
        ...prev,
        [field]: value,
        coins: []
      }))
    }
    else {
      setCurrentGroup((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  // Manejar cambios en la configuración dentro del modal
  const handleConfigChange = (type, paramName, field, value) => {
    setCurrentGroup((prev) => ({
      ...prev,
      [`${type}Config`]: {
        ...prev[`${type}Config`],
        [paramName]: {
          ...prev[`${type}Config`][paramName],
          [field]: value,
        },
      },
    }));
  };

  // Guardar grupo (agregar o editar)
  const saveGroup = () => {
    if (!currentGroup.groupName) {
      alertError({ customMessage: "Group Name is required." });
      return;
    }

    if (currentGroup.coins.length === 0) {
      alertError({ customMessage: "At least one coin must be selected." });
      return;
    }

    const newGroup = {...currentGroup};

    if (isEditMode) {
      const updatedGroups = [...strategy.groups];
      updatedGroups[currentGroup.index] = newGroup;
      setStrategy((prev) => ({
        ...prev,
        groups: updatedGroups,
      }));
    } else {
      setStrategy((prev) => ({
        ...prev,
        groups: [...prev.groups, newGroup],
      }));
    }
    setOpenGroupModal(false);
    setCurrentGroup(null);
  };

  // Manejar selección de todas las coins
  const handleSelectAllCoins = (event) => {
    if (event.target.checked) {
      setCurrentGroup((prev) => ({
        ...prev,
        coins: allCoins,
      }));
    } else {
      setCurrentGroup((prev) => ({
        ...prev,
        coins: [],
      }));
    }
  };

  // Guardar estrategia
  const saveStrategy = () => {
    if (exchanges.length === 0) {
      alertError({ customMessage: "Please select an exchange." });
      return;
    }

    if (!strategy.fullName || !strategy.description) {
      alertError({ customMessage: "Full Name and Description are required." });
      return;
    }

    // **Validaciones adicionales para bots basados en EMA**
    if (isEMABot(strategy.bot)) {
      if (
        !strategy.candleInterval ||
        !strategy.emaPeriod ||
        !strategy.atrPeriod ||
        strategy.filterTop === undefined ||
        strategy.filterBottom === undefined
      ) {
        alertError({ customMessage: "All EMA filters must be filled." });
        return;
      }
    }

    const saveStrategyPayload = {
      ...strategy,
      // exchange: exchange,
    };

    if (!strategyId) {
      postStrategy(saveStrategyPayload)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Strategy successfully created.",
            });
            history.push("/strategies");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save strategy.",
          });
        });
    } else {
      updateStrategy(strategyId, saveStrategyPayload)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/strategies");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  };

  const isEMABot = (bot) => {
    const emaBots = ["Scalping", "APEX-SCALPING"];
    return emaBots.includes(bot);
  };

  const Groups = () => {
    let groups = [];

    for (let i = 0; i < strategy?.groups?.length; ++i) {
      const actG = strategy.groups[i];
      groups.push(actG);
    }

    return (
      <>
        {groups.map((group, index) => (
          <ExpansionPanel key={index}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-content-${index}`}
              id={`panel-header-${index}`}
            >
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {group.groupName}
                  </Typography>
                  <Typography variant="body2">
                    {group.coins.slice(0, 3).join(", ")}
                    {group.coins.length > 3 && "..."}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="flex-end"
                >
                  <IconButton
                    onClick={() => editGroup(group, index)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => removeGroup(index)}
                    color="secondary"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <b>Long Configuration:</b>
                  </Typography>
                  <ConfigParameters
                    config={group.longConfig}
                    handleConfigChange={(param, field, value) =>
                      handleConfigChange(
                        "long",
                        param,
                        field,
                        value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <b>Short Configuration:</b>
                  </Typography>
                  <ConfigParameters
                    config={group.shortConfig}
                    handleConfigChange={(param, field, value) =>
                      handleConfigChange(
                        "short",
                        param,
                        field,
                        value
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
        {groups.length === 0 && (
          <Typography variant="body1" color="textSecondary">
            No groups added. Click the "+" button to add a group.
          </Typography>
        )}
      </>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      {isLoadingData ? (
        <ContentSkeleton />
      ) : (
        <>
          <Card>
            <CardHeader
              title={strategyId ? "Edit Strategy" : "Create Strategy"}
            />
            <CardBody>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    options={exchanges}
                    getOptionLabel={(option) => option.fullName}
                    value={strategy?.exchanges || []}
                    onChange={(event, newValue) => {
                      console.log(newValue)
                      let newValues = [];
                      for (let i = 0; i < newValue.length; ++i) {
                        const act = newValue[i];
                        newValues.push({
                          ...act,
                          exchangeId: act?._id,
                        })
                      }

                      setStrategy((prev) => ({
                        ...prev,
                        exchanges: newValues,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Exchanges"
                        placeholder="Exchanges..."
                      />
                    )}
                  />
                </Grid>

                {/* Bot Selection */}
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  >
                    <InputLabel id="bot-label">Bot</InputLabel>
                    <Select
                      labelId="bot-label"
                      value={strategy.bot}
                      onChange={(e) =>
                        setStrategy({ ...strategy, bot: e.target.value })
                      }
                      label="Bot"
                      disabled={exchanges.length === 0}
                    >
                      {bots.map((bot) => (
                        <MenuItem key={bot} value={bot}>
                          {bot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Full Name */}
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={strategy.fullName}
                    onChange={(e) =>
                      setStrategy({ ...strategy, fullName: e.target.value })
                    }
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    rows={4}
                    value={strategy.description}
                    onChange={(e) =>
                      setStrategy({ ...strategy, description: e.target.value })
                    }
                  />
                </Grid>

                {/* MIS CAMBIOS */}
                {isEMABot(strategy.bot) && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Candle Interval (min)"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.candleInterval}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            candleInterval: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="EMA Period"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.emaPeriod}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            emaPeriod: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Filter Top"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.filterTop}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            filterTop: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Filter Bottom"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.filterBottom}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            filterBottom: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Take Profit 1"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={strategy.takeProfit1}
                    onChange={(e) =>
                      setStrategy({ ...strategy, takeProfit1: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Take Profit 2"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={strategy.takeProfit2}
                    onChange={(e) =>
                      setStrategy({ ...strategy, takeProfit2: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Stop Loss 1"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={strategy.stopLoss1}
                    onChange={(e) =>
                      setStrategy({ ...strategy, stopLoss1: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Stop Loss 2"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={strategy.stopLoss2}
                    onChange={(e) =>
                      setStrategy({ ...strategy, stopLoss2: e.target.value })
                    }
                  />
                </Grid>

                {isEMABot(strategy.bot) && (
                  <>
                    {/* Candle Interval */}
                    <Grid item xs={12} sm={6}>
                      
                    </Grid>

                    {/* EMA Period */}
                    <Grid item xs={12} sm={6}>
                      
                    </Grid>

                    {/* ATR Period
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="ATR Period"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.atrPeriod}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            atrPeriod: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid> */}

                    {/* Section for the account configuration */}
                    {/* <Grid item xs={12}>
                      <Typography variant="h6">
                        Account Configuration
                      </Typography>
                    </Grid>

                    Account Amount
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Account Amount"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.accountAmount}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            accountAmount: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    Percentage Per Trade
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Percentage Per Trade"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.percentagePerTrade}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            percentagePerTrade: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    Leverage
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Leverage"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={strategy.leverage}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            leverage: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid> */}
                  </>
                )}
                {/* Long and Short Activation */}
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={strategy.longActive}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            longActive: e.target.checked,
                          })
                        }
                        name="longActive"
                        color="primary"
                      />
                    }
                    label="Longs Activados"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={strategy.shortActive}
                        onChange={(e) =>
                          setStrategy({
                            ...strategy,
                            shortActive: e.target.checked,
                          })
                        }
                        name="shortActive"
                        color="primary"
                      />
                    }
                    label="Shorts Activados"
                  />
                </Grid>

                {/* Groups Management */}
                {!refresh && (
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="Groups">
                        <IconButton onClick={addGroup} color="primary">
                          <Add />
                        </IconButton>
                      </CardHeader>
                      <CardBody>
                        <Groups />
                      </CardBody>
                    </Card>
                  </Grid>
                )}
              </Grid>

              {/* Active Status */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={strategy.active}
                    onChange={(e) =>
                      setStrategy({ ...strategy, active: e.target.checked })
                    }
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </CardBody>
          </Card>

          {/* Action Buttons */}
          <Box mt={2}>
            <Button
              onClick={() => history.push("/strategies")}
              variant="outlined"
              color="primary"
              style={{
                marginRight: "20px",
                backgroundColor: "#cc02d6",
                color: "#fff",
              }}
            >
              Back
            </Button>
            <Button
              onClick={saveStrategy}
              variant="contained"
              color="primary"
              style={{ marginRight: "20px", backgroundColor: "#cc02d6" }}
            >
              Save Strategy
            </Button>
            {strategyId && (
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="contained"
                  color="secondary"
                  style={{ backgroundColor: "#F64E60" }}
                >
                  Delete Strategy
                </Button>
              </MuiThemeProvider>
            )}
          </Box>

          {/* Confirm Delete Dialog */}
          {strategyId && (
            <ConfirmDialog
              title={"Are you sure you want to delete this strategy?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteStrategy(strategyId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Strategy deleted successfully",
                      });
                      history.push("/strategies");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete strategy.",
                    });
                  });
              }}
            />
          )}

          {/* Group Modal */}
          <Dialog
            open={openGroupModal}
            onClose={() => setOpenGroupModal(false)}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>{isEditMode ? "Edit Group" : "Add Group"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {/* Group Name */}
                <Grid item xs={12}>
                  <TextField
                    label="Group Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={currentGroup?.groupName || ""}
                    onChange={(e) =>
                      handleGroupChange("groupName", e.target.value)
                    }
                  />
                </Grid>

                {/*CANDLE SOURCE */}
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  >
                    <InputLabel id="candle-source-label">
                      Candle Source
                    </InputLabel>
                    <Select
                      labelId="candle-source-label"
                      value={currentGroup?.source}
                      onChange={(e) =>
                        handleGroupChange("source", e.target.value)
                      }
                      label="Candle Source"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="close">Close</MenuItem>
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="ohlc4">Ohlc4</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {/* <TextField
                    label="Exchange"
                    variant="outlined"
                    fullWidth
                    required
                    value={currentGroup?.exchange || ""}
                    onChange={(e) =>
                      handleGroupChange("exchange", e.target.value)
                    }
                  /> */}

                  <Autocomplete
                    options={strategy?.exchanges || []}
                    getOptionLabel={(option) => option.fullName}
                    value={exchanges.find((x) => x.fullName === currentGroup?.exchange) || []}
                    onChange={(event, newValue) => {
                      console.log(newValue)
                      handleGroupChange("exchange", newValue.fullName);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Exchange"
                        placeholder="Exchange..."
                      />
                    )}
                  />
                </Grid>

                {/* Account Configuration */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Account Amount"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={currentGroup?.accountAmount}
                    onChange={(e) => {
                      handleGroupChange("accountAmount", e.target.value)
                    }}
                  />
                </Grid>

                {/* Percentage Per Trade */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Percentage Per Trade"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={currentGroup?.percentagePerTrade}
                    onChange={(e) => {
                      handleGroupChange("percentagePerTrade", e.target.value)
                    }}
                  />
                </Grid>

                {/* Leverage */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Leverage"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={currentGroup?.leverage}
                    onChange={(e) => {
                      handleGroupChange("leverage", e.target.value)
                    }}
                  />
                </Grid>

                {/* Coin Selection */}
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={allCoins.find((x) => x.exchange === currentGroup?.exchange)?.symbols || []}
                    getOptionLabel={(option) => option}
                    value={currentGroup?.coins || []}
                    onChange={(event, newValue) => {
                      setCurrentGroup((prev) => ({
                        ...prev,
                        coins: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Coins"
                        placeholder="Coins"
                      />
                    )}
                  />
                </Grid>
                {/* Select All Checkbox */}
                {/* <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          currentGroup?.coins.length === allCoins.length &&
                          allCoins.length > 0
                        }
                        onChange={handleSelectAllCoins}
                        name="selectAllCoins"
                        color="primary"
                      />
                    }
                    label="Select All"
                  />
                </Grid> */}

                {/* Configurations Tabs */}
                {/* <Grid item xs={12}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={(e, newValue) => setTab(newValue)}
                      aria-label="config tabs"
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label="Long Configuration" {...a11yProps(0)} />
                      <Tab label="Short Configuration" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tab} index={0}>
                    <ConfigParameters
                      config={currentGroup?.longConfig || {}}
                      handleConfigChange={(param, field, value) =>
                        handleConfigChange("long", param, field, value)
                      }
                    />
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <ConfigParameters
                      config={currentGroup?.shortConfig || {}}
                      handleConfigChange={(param, field, value) =>
                        handleConfigChange("short", param, field, value)
                      }
                    />
                  </TabPanel>
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenGroupModal(false)}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={saveGroup} color="primary" variant="contained">
                {isEditMode ? "Update Group" : "Add Group"}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </MuiThemeProvider>
  );
}
