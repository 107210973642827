import { authClient, API } from "../index";

// Get all strategies
export const getExchanges = () => {
  return authClient().get(`${API}/exchange?getDisabled=true`);
};

// Get exchange by id
export const getExchangeById = (id) => {
  return authClient().get(`${API}/exchange/${id}`);
};

// Delete exchange
export const deleteExchange = (id) => {
  return authClient().delete(`${API}/exchange/${id}`);
};

// Create exchange
export const postExchange = async (exchange) => {
  return authClient().post(`${API}/exchange`, exchange);
};

// Update exchange
export const updateExchange = async (id, exchange) => {
  return authClient().put(`${API}/exchange/${id}`, exchange);
};

// Change status exchange
export const changeStatusExchange = async (id, active) => {
  return authClient().put(`${API}/exchange/change-status/${id}`, { active });
};

export const getAllCoins = async (exchange) => {
  return authClient().get(`${API}/exchange/coins`, { params: { exchange } });
};
