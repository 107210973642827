import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  MuiThemeProvider,
  createMuiTheme,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError } from "../../../../utils/logger";
import { getExchangeById, updateExchange } from "../../../../api/exchange";

// Crear tema personalizado con el color #cc02d6
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc02d6",
    },
    secondary: {
      main: "#F64E60",
    },
  },
});

export default function EditExchangePage() {
  const [exchange, setExchange] = useState({
    fullName: "",
    active: true,
  });
  const exchangeId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    console.log(exchangeId)
    if (!exchangeId) history.push("/exchanges");
    getExchangeById(exchangeId)
      .then((res) => {
        if (res.status === 200) {
          setExchange(res.data)
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error,
          customMessage: "Could not get exchange."
        })
      })
  }, [])

  const handleChange = (element) => (event) => {
    setExchange({...exchange, [element]: event.target.value});
  }

  function saveExchange() {
    updateExchange(exchangeId, exchange)
      .then((res) => {
        history.push("/exchanges");
      })
      .catch((error) => {
        alertError({
          error,
          customMessage: "Could not save exchange"
        })
      })
  }

  return (
    <MuiThemeProvider theme={theme}>
      {isLoadingData ? (
        <ContentSkeleton />
      ) : (
        <>
          <Card>
            <CardHeader
              title={"Edit Strategy"}
            />
            <CardBody>
            <TextField
              id={`fullName`}
              label={`Full Name`}
              value={exchange?.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={exchange?.active}
                  onChange={() => setExchange({ ...exchange, active: !exchange.active })}
                  name="checkActive"
                />
              }
              label="Active"
            />
            </CardBody>
          </Card>

          {/* Action Buttons */}
          <Box mt={2}>
            <Button
              onClick={() => history.push("/exchanges")}
              variant="outlined"
              color="primary"
              style={{
                marginRight: "20px",
                backgroundColor: "#cc02d6",
                color: "#fff",
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {saveExchange()}}
              variant="contained"
              color="primary"
              style={{ marginRight: "20px", backgroundColor: "#cc02d6" }}
            >
              Save exchange
            </Button>
          </Box>
        </>
      )}
    </MuiThemeProvider>
  );
}
